module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-J9E6JQC5ZN"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bitpas/gatsby-plugin-seo/gatsby-browser.js'),
      options: {"plugins":[],"helmet":{"title":"Lubbock venue tour and wedding event","titleTemplate":"%s – Lubbock Venue Crawl","meta":[{"name":"description","content":"Tour the best Lubbock wedding venues and connect with quality wedding professionals. Plan your wedding day in one afternoon while having fun!"},{"name":"image","content":"https://lbkvenuecrawl.com/site.png"},{"name":"author","content":"Ryan Todd Garza"},{"name":"og:title","content":"Lubbock venue tour and wedding event - Lubbock Venue Crawl"},{"property":"og:type","content":"website"},{"property":"og:url","content":"https://lbkvenuecrawl.com"},{"name":"og:image","content":"https://lbkvenuecrawl.com/site.png"},{"name":"twitter:card","content":"summary_large_image"}],"link":[{"rel":"icon","href":"/favicon.svg","media":"(prefers-color-scheme: no-preference)"},{"rel":"icon","href":"/favicon.svg","media":"(prefers-color-scheme: light)"},{"rel":"icon","href":"/favicon-inverted.svg","media":"(prefers-color-scheme: dark)"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
